// This should be the only place where we have an import from "launchdarkly-js-client-sdk"
// See: https://docs.launchdarkly.com/sdk/client-side/react/react-web#importing-types

import { Capacitor } from "@capacitor/core";
// eslint-disable-next-line no-restricted-imports
import { type LDSingleKindContext } from "launchdarkly-js-client-sdk";

import { type CbhAppInfo } from "../lib";
import { getOtaBuildIdAsNumber } from "./getOtaBuildIdAsNumber";

export const LD_USER_KIND = "user";

// The purpose of this type is to ensure that we're sending
// the same context to LaunchDarkly with each `identify` call.
interface CbhUserContext extends LDSingleKindContext {
  // Whether the user is an anonymous user.
  anonymous: boolean;
  // User's ID or undefined for anonymous users.
  key?: string;
  // LD context kind.
  kind: typeof LD_USER_KIND;
  // User's name.
  name: string;
  // User's email.
  email: string;
  // User's stage.
  stage: string;
  // App version.
  version: string;
  // Legacy OTA build number as string. Retained for backwards compatibility.
  otaBuildId: string;
  // OTA build number. Confirmed by Ionic to be incremental.
  otaBuildNumber: number;
  // User's metropolitan statistical area.
  msa: string;
  // User's city address.
  city: string;
  // User's state address.
  state: string;
  // User's qualification.
  qualification: string;
  // User's onboarding estimated region.
  // This should have the "<country code>-<state code>" format.
  onboardingEstimatedRegion: string;
  // FIXME: to be removed.
  // Whether browsing shifts across US is available.
  browsingShiftsAcrossTheUsAvailable: boolean;
  // One of: 'ios' | 'android' | 'web'
  platform: string;
  // User's created at timestamp formatted as ISO-8601.
  createdAt: string;
}

export function getAnonymousUserContext(cbhAppInfo: CbhAppInfo): CbhUserContext {
  const platform = Capacitor.getPlatform();

  return {
    anonymous: true,
    // "key" is omitted.
    kind: LD_USER_KIND,
    name: "",
    email: "",
    stage: "",
    version: cbhAppInfo.version,
    otaBuildId: cbhAppInfo.otaBuildId,
    otaBuildNumber: getOtaBuildIdAsNumber(cbhAppInfo.otaBuildId),
    msa: "",
    city: "",
    state: "",
    qualification: "",
    onboardingEstimatedRegion: "",
    browsingShiftsAcrossTheUsAvailable: true,
    platform,
    createdAt: "",
  };
}

/** Properties of a signed-in user. */
export interface SignedInUserProperties {
  userId: string;
  name: string;
  email: string;
  stage: string;
  msa: string;
  city: string;
  state: string;
  stateCode: string;
  countryCode: string;
  qualification: string;
  createdAt: string;
  qualifications: string[];
}

export function getSignedInUserContext(
  cbhAppInfo: CbhAppInfo,
  properties: SignedInUserProperties
): CbhUserContext {
  const platform = Capacitor.getPlatform();

  return {
    anonymous: false,
    key: properties.userId,
    kind: LD_USER_KIND,
    name: properties.name,
    email: properties.email,
    stage: properties.stage,
    version: cbhAppInfo.version,
    otaBuildId: cbhAppInfo.otaBuildId,
    otaBuildNumber: getOtaBuildIdAsNumber(cbhAppInfo.otaBuildId),
    msa: properties.msa,
    city: properties.city,
    state: properties.state,
    qualification: properties.qualification,
    onboardingEstimatedRegion: `${properties.countryCode}-${properties.stateCode}`,
    browsingShiftsAcrossTheUsAvailable: true,
    platform,
    createdAt: properties.createdAt,
    qualifications: properties.qualifications,
  };
}

/** Returns the LaunchDarkly context for an anonymous user. */
export function getAnonymousLdUser(cbhAppInfo: CbhAppInfo): CbhUserContext {
  return getAnonymousUserContext(cbhAppInfo);
}

/** Returns the LaunchDarkly context for a signed-in user. */
export function getSignedInLdUser(
  cbhAppInfo: CbhAppInfo,
  properties: SignedInUserProperties
): CbhUserContext {
  return getSignedInUserContext(cbhAppInfo, properties);
}
